import MicroModal from "micromodal"

MicroModal.init({
  disableScroll: true,
  awaitCloseAnimation: true,
  onShow: () => {
    // prevent device focus zoom effect from scrolling modal content out of viewport
    document.getElementById('signup_email').blur()
  },
  onClose: () => {
    const first = document.getElementById('first')
    const firstHeader = document.getElementById('sign-up-modal-title')
    const second = document.getElementById('second')
    const secondHeader = document.getElementById('confirmation-modal-title')
    const confirmedEmail = document.getElementById('confirmed-email')
    const formErrors = document.getElementById('form-errors')

    setTimeout(() => {
      confirmedEmail.textContent = ""
      formErrors.textContent = ""

      first.classList.remove('hidden')
      first.classList.remove('hide')
      firstHeader.classList.remove('hidden')
      firstHeader.classList.remove('hide')
      firstHeader.setAttribute("aria-hidden", false)

      second.classList.remove('show')
      secondHeader.classList.remove('show')
      secondHeader.setAttribute("aria-hidden", true)
    }, 400)
  }
})
